import React from "react";
import { DateTime } from "luxon";

import ApiContext from "./ApiContext";

const cache = {};

export default class Entry extends React.Component {
	static contextType = ApiContext;

	constructor(props) {
		super(props);
		this.state = {
			data: null,
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.id !== prevProps.id) {
			this.getData(this.context);
		}
	}

	componentDidMount() {
		this.getData(this.context);
	}

	async getData(context) {
		const id = this.props.id;

		if (!id) {
			console.warn("No ID");
			return;
		}

		let data;
		if (id in cache && cache[id].expires > DateTime.local()) {
			data = cache[id].data;
		} else {
			data = (await context.callApi(`application/${id}/upload-data`)).data;
			const expiresTimestampString = (new URL((data.headshot || data.video).url)).searchParams.get("Expires");
			cache[id] = {
				expires: DateTime.fromMillis(parseInt(expiresTimestampString, 10) * 1e3),
				data: data,
			};
		}

		this.setState({
			data,
		});
	}

	render() {
		const {
			id,
			...otherProps
		} = this.props;
		const data = this.state.data;

		return (
			<EntryImpl
				id={id}
				headshotType={data ? data.headshot.type : null}
				headshotUrl={data ? data.headshot.url : null}
				videoType={data ? data.video.type : null}
				videoUrl={data ? data.video.url : null}
				{...otherProps}
			/>
		);
	}
}

const EntryImpl = ({
	actingExperience,
	actingExperienceDetail,
	agreeToTerms,
	city,
	clientIp,
	commitToDates,
	createdAt,
	currentBrokerage,
	currentlyLicensed,
	email,
	firstLicensed,
	headshotFilename,
	headshotType,
	headshotUrl,
	id,
	marketingActivities,
	name,
	phone,
	socialMedia,
	stokeLevel,
	uniqueSkills,
	videoFilename,
	videoType,
	videoUrl,
	willBeLicensedAtEndOfMarch,
}) => (
	<article>
		<h2>{name}</h2>
		{createdAt && (
			<p>Applied <time dateTime={createdAt}>{DateTime.fromISO(createdAt).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}</time></p>
		)}

		<h3>Contact details</h3>
		<h4>Email address</h4>
		<p>{email ? <a href={`mailto:${email}`}>{email}</a> : <Null />}</p>
		<h4>Phone number</h4>
		<p>{phone ? <a href={`tel:${phone}`}>{phone}</a> : <Null />}</p>

		<h3>City</h3>
		<p><TextOrNull value={city} /></p>

		<h3>Social accounts</h3>
		<p><TextOrNull value={socialMedia} /></p>

		<h3>Unique skills</h3>
		<p><TextOrNull value={uniqueSkills} /></p>

		{headshotUrl && (
			<>
				<h3>Headshot</h3>
				<img src={headshotUrl} style={{ maxWidth: "640px", height: "auto" }} alt={headshotFilename} />
			</>
		)}

		{videoUrl && (
			<>
				<h3>Video</h3>
				<video controls style={{ maxWidth: "640px", height: "auto" }} key={videoUrl}>
					<source src={videoUrl} type={videoType} />
					<p><a href={videoUrl}>{videoFilename}</a></p>
				</video>
				<p><a href={videoUrl}>{videoFilename}</a></p>
			</>
		)}

		<h3>Can commit to dates</h3>
		<p><Boolean value={commitToDates} /></p>

		<h3>Acting experience</h3>
		<p><Boolean value={actingExperience} /></p>
		{actingExperience && actingExperienceDetail && (
			<p><TextOrNull value={actingExperienceDetail} /></p>
		)}

		<h3>Real estate</h3>
		<h4>First licensed</h4>
		<p><TextOrNull value={firstLicensed} /></p>
		<h4>Currently licensed</h4>
		<p><Boolean value={currentlyLicensed} /></p>
		<h4>Will be licensed at the end of March</h4>
		<p><Boolean value={willBeLicensedAtEndOfMarch} /></p>
		<h4>Current brokerage</h4>
		<p><TextOrNull value={currentBrokerage} /></p>

		<h3>Current marketing activities</h3>
		<p><TextOrNull value={marketingActivities} /></p>

		<h3>Agreed to terms</h3>
		<p><Boolean value={agreeToTerms} /></p>

		<h3>Stokage level</h3>
		<p><TextOrNull value={stokeLevel} /></p>
	</article>
);

function TextOrNull({ value }) {
	if (value == null) {
		return <Null />;
	}
	return <span style={{ whiteSpace: "pre-line" }}>{value}</span>;
}

function Null() {
	return <span style={{ opacity: 0.5 }}>(not given)</span>;
}

function Boolean({ value }) {
	if (value === true) {
		return "✔️";
	}
	if (value === false) {
		return "❌";
	}
	return "❓";
}

