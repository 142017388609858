import React from "react";

import ApiContext from "./ApiContext";

import Loading from "./Loading";
import Login from "./Login";
import Table from "./Table";

class App extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			credentialsSubmitting: false,
			data: null,
			loading: false,
			password: window.localStorage.getItem("password") || "",
			showLogin: false,
			username: window.localStorage.getItem("username") || "",
		};

		// Show login if username or password is missing
		this.state.showLogin = this.state.username === "" || this.state.password === "";

		if (!this.state.showLogin) {
			this.state.loading = true;
		}
	}

	callApi = async (path, options = {}) => {
		const headers = new Headers();
		const { username, password } = this.state;
		headers.append("Authorization", `Basic ${window.btoa(`${username}:${password}`)}`);
		const response = await fetch(`${process.env.REACT_APP_API_URL}/${path}`, {
			headers: headers,
		});
		if (!response.ok) {
			const error = new Error(`Unexpected response with code ${response.code}`);
			error.response = response;
			if (response.status === 401) {
				error.message = "Unauthorized";
			}
			throw error;
		}
		return response.json();
	}

	onCredentialsChange = (event) => {
		this.setState({
			[event.currentTarget.name]: event.currentTarget.value,
		});
	}

	onCredentialsSubmit = async (event) => {
		event.preventDefault();

		this.setState({
			credentialsSubmitting: true,
			loading: true,
		});

		let data = null;
		try {
			data = await this.getData();
		} catch (error) {
			alert(error.message);
			return;
		} finally {
			this.setState({
				credentialsSubmitting: false,
				loading: false,
			});
		}

		window.localStorage.setItem("username", this.state.username);
		window.localStorage.setItem("password", this.state.password);

		this.setState({
			data,
			showLogin: false,
		});
	}

	async componentDidMount() {
		const { data, username, password } = this.state;

		if (username !== "" && password !== "" && data == null) {
			return this.reloadData();
		}
	}

	reloadData = async () => {
		this.setState({
			loading: true,
		});

		let data;
		try {
			data = await this.getData();
		} catch (error) {
			if (error.response && error.response.status === 401) {
				this.setState({
					showLogin: true,
				});
			}
			return;
		} finally {
			this.setState({
				credentialsSubmitting: false,
				loading: false,
			});
		}

		this.setState({ data });
	}

	async getData() {
		return (await this.callApi("application")).data;
	}

	render() {
		const {
			credentialsSubmitting,
			data,
			loading,
			password,
			showLogin,
			username,
		} = this.state;

		if (showLogin) {
			return <Login username={username} password={password} onChange={this.onCredentialsChange} onSubmit={this.onCredentialsSubmit} submitting={credentialsSubmitting} />;
		}

		if (data == null) {
			return <Loading />;
		}

		return (
			<ApiContext.Provider value={{
				callApi: this.callApi,
			}}>
				<button type="button" onClick={this.reloadData} disabled={loading}>
					{loading ? (
						<Loading />
					) : (
						"Refresh"
					)}
				</button>
				<Table data={data} />
			</ApiContext.Provider>
		);
	}
}

export default App;
