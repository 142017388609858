import React from "react";
import ReactTable from "react-table";
import { DateTime } from "luxon";

import "react-table/react-table.css";

import Entry from "./Entry";

function BooleanCell({ value }) {
	if (value === true) {
		return "✔️";
	}
	if (value === false) {
		return "❌";
	}
	return "❓";
}

function DateCell({ value }) {
	return value == null ? null : value.toLocaleString(DateTime.DATETIME_MED);
}

function mutateData(data) {
	return data.map((row) => ({
		...row,
		createdAt: row.createdAt == null ? null : DateTime.fromISO(row.createdAt),
	}));
}

export default class Table extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedRow: null,
		};
	}

	getTrProps = (state, rowInfo, column, instance) => {
		if (!rowInfo || !rowInfo.row) {
			return {};
		}

		return {
			onClick: (event, handleOriginal) => {
				this.setState({
					selectedRow: rowInfo.original.id,
				});
				if (handleOriginal) {
					handleOriginal();
				}
			},
			style: {
				backgroundColor: this.state.selectedRow === rowInfo.original.id ? "gold" : undefined,
				cursor: "pointer",
			},
		};
	}

	render() {
		const { data } = this.props;
		const selectedRowId = this.state.selectedRow;
		const selectedRow = selectedRowId == null ? null : data.Items.find((el) => el.id === selectedRowId);
		return (
			<div>
				<ReactTable
					data={data.Items}
					resolveData={mutateData}
					getTrProps={this.getTrProps}
					columns={[
						{
							Header: "Applied",
							accessor: "createdAt",
							Cell: DateCell,
						},
						{
							Header: "Name",
							accessor: "name",
						},
						{
							Header: "Contact details",
							columns: [
								{
									Header: "Email",
									accessor: "email",
								},
								{
									Header: "Phone",
									accessor: "phone",
								},
							],
						},
						{
							Header: "City",
							accessor: "city",
						},
						{
							Header: "Dates OK",
							accessor: "commitToDates",
							Cell: BooleanCell,
						},
						{
							Header: "Act XP",
							accessor: "actingExperience",
							Cell: BooleanCell,
						},
						{
							Header: "Licensed",
							columns: [
								{
									Header: "Originally",
									accessor: "firstLicensed",
								},
								{
									Header: "Currently",
									accessor: "currentlyLicensed",
									Cell: BooleanCell,
								},
								{
									Header: "In March",
									accessor: "willBeLicensedAtEndOfMarch",
									Cell: BooleanCell,
								},
							],
						},
						{
							Header: "Brokerage",
							accessor: "currentBrokerage",
						},
						{
							Header: "Terms",
							accessor: "agreeToTerms",
							Cell: BooleanCell,
						},
						{
							Header: "Stoke",
							accessor: "stokeLevel",
						},
					]}
					defaultSorted={[
						{
							id: "createdAt",
							desc: true,
						},
					]}
				/>

				{data.ScannedCount < data.Count && (
					<p>
						Retrieved {data.ScannedCount} of total {data.Count} entries
					</p>
				)}

				{selectedRow && (
					<Entry {...selectedRow} />
				)}
			</div>
		);
	}
}
