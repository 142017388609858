import React from "react";

const Login = ({ username, password, onChange, onSubmit, submitting }) => (
	<form onSubmit={onSubmit}>
		<label>
			Username{" "}
			<input type="text" name="username" autoComplete="username" value={username} onChange={onChange} disabled={submitting} required />
		</label>
		<label>
			Password{" "}
			<input type="password" name="password" autoComplete="password" value={password} onChange={onChange} disabled={submitting} required />
		</label>
		<button type="submit" disabled={submitting}>
			Log in
		</button>
	</form>
);

export default Login;
